<template>
  <section>
    <div class="w-full">
      <div class="w-full flex justify-end gap-2">
        <div v-if="documento">
          <div class="bg-blue-300 flex justify-between rounded-md p-2 text-blue-600 font-bold text-sm border border-r-2">
            <div class="flex gap-2">
              <p>{{ documento ? documento.name : 'pacientes.xlsx' }}</p>
            </div>
            <button @click="eliminarArchivo">
              <i class="pi pi-times text-sm"></i>
            </button>
          </div>
        </div>
        <FileUpload
          v-else
          mode="basic"
          name="demo[]"
          url="./upload.php"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          class="bg-white text-blue-600"
          chooseLabel="Importar pacientes masivo"
          @change="subirPaciente($event)"
          ref="file"
        ></FileUpload>
        <Button @click="contratosStore.descargarPlantillaMasivoPacientes()" label="Descargar plantilla pacientes" icon="pi pi-download" severity="secondary" />
      </div>
    </div>
  </section>
</template>
<script>
import { ref } from 'vue'
  import { useContratosStore } from '../../../stores/contratos.store'
  import { FilterMatchMode } from 'primevue/api'
  // import ServiceContrato from '../../../services/contrato-precio.service'
  // import Swal from 'sweetalert2'
  import { helper } from '@/utils/helper'
  export default {
    name: 'crearContratoPacientes',
    setup () {
      const contratosStore = useContratosStore()
      // const _serviceContrato = new ServiceContrato()
      const filtros = ref({
        NoIdent: { value: null, matchMode: FilterMatchMode.CONTAINS },
        FechaInicio: { value: null, matchMode: FilterMatchMode.CONTAINS },
        FechaFin: { value: null, matchMode: FilterMatchMode.CONTAINS }
      })
      const documento = ref()
      const file = ref()
      const subirPaciente = async (event) => {
        const archivo = file.value.files[0]
        documento.value = archivo
        if (archivo) {
          const reader = new FileReader()
          reader.onload = async (event) => {
            contratosStore.pacientes_ingresados = { base64: await helper.base64String(archivo) }
            // const validaPac = await _serviceContrato.validPacientes({ base64: await helper.base64String(archivo) })
            // if (validaPac.data) {
            //   Swal.fire({
            //     icon: 'warning',
            //     title: 'Validacion Pacientes',
            //     text: validaPac.data
            //   })
            // }
          }
          reader.readAsArrayBuffer(archivo)
        }
      }
      const eliminarArchivo = () => {
        file.value = null
        documento.value = null
        contratosStore.pacientes_ingresados = []
      }
      return {
        contratosStore,
        subirPaciente,
        file,
        filtros,
        eliminarArchivo,
        documento
      }
    }
  }
</script>
